<template>
  <dashboard-content full-width>
    <page-header sticky>
      <h1 class="mb-4">
        Contacts
      </h1>
    </page-header>
    <v-row>
      <v-col class="col-12 py-0">
        <firebase-table
          :headers="headers"
          collection="contacts"
        >
          <template v-slot:item.createdAt="{ item }">
            {{ formatDatetimeLocal(item.createdAt) }}
          </template>
          <template v-slot:item.action="{ item }">
            <v-icon
              small
              class="mr-2"
              @click="showItem(item.id)"
            >
              {{ mdiEye }}
            </v-icon>
          </template>
        </firebase-table>
      </v-col>
    </v-row>
  </dashboard-content>
</template>

<script>
import { mdiEye } from '@mdi/js'
import DashboardContent from '@/components/dashboard/DashboardContent'
import FirebaseTable from '@/components/FirebaseTable'
import PageHeader from '@/components/dashboard/PageHeader.vue'

export default {
  components: { DashboardContent, FirebaseTable, PageHeader },
  data() {
    return {
      mdiEye,
      headers: [
        { text: 'Created at', value: 'createdAt' },
        { text: 'Name', value: 'name' },
        { text: 'Email', value: 'email' },
        { text: 'Message', value: 'message' },
        { text: 'Actions', value: 'action', sortable: false }
      ]
    }
  },
  methods: {
    showItem(id) {
      this.$router.push({ name: 'contactShow', params: { id } })
    }
  }
}
</script>
